import { LocationPathname } from '@manigo/manigo-commons';
import { CardDetails } from '@manigo/manigo-domain-typings';


export const FETCH_CARD_DETAILS = 'FETCH_CARD_DETAILS';
export const FETCH_CARD_DETAILS_SUCCESS = 'FETCH_CARD_DETAILS_SUCCESS';
export const FETCH_CARD_DETAILS_FAILURE = 'FETCH_CARD_DETAILS_FAILURE';
export const CLEAR_CARD_DETAILS = 'CLEAR_CARD_DETAILS';


export const UPDATE_CARD_LIMITS = 'UPDATE_CARD_LIMITS';
export const UPDATE_CARD_LIMITS_SUCCESS = 'UPDATE_CARD_LIMITS_SUCCESS';
export const UPDATE_CARD_LIMITS_FAILURE = 'UPDATE_CARD_LIMITS_FAILURE';

export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

export const UPDATE_CARD_STATUS = 'UPDATE_CARD_STATUS';
export const UPDATE_CARD_STATUS_SUCCESS = 'UPDATE_CARD_STATUS_SUCCESS';
export const UPDATE_CARD_STATUS_FAILURE = 'UPDATE_CARD_STATUS_FAILURE';

export const UNBLOCK_CARD_PIN = 'UNBLOCK_CARD_PIN';
export const UNBLOCK_CARD_PIN_SUCCESS = 'UNBLOCK_CARD_PIN_SUCCESS';
export const UNBLOCK_CARD_PIN_FAILURE = 'UNBLOCK_CARD_PIN_FAILURE';

export const RESET_CONTACTLESS_CARD_LIMIT = 'RESET_CONTACTLESS_CARD_LIMIT';
export const RESET_CONTACTLESS_CARD_LIMIT_SUCCESS = 'RESET_CONTACTLESS_CARD_LIMIT_SUCCESS';
export const RESET_CONTACTLESS_CARD_LIMIT_FAILURE = 'RESET_CONTACTLESS_CARD_LIMIT_FAILURE';

export const ACTIVATE_CARD = 'ACTIVATE_CARD';
export const ACTIVATE_CARD_SUCCESS = 'ACTIVATE_CARD_SUCCESS';
export const ACTIVATE_CARD_FAILURE = 'ACTIVATE_CARD_FAILURE';

export const UPDATE_CARD_SETTINGS = 'UPDATE_CARD_SETTINGS';
export const UPDATE_CARD_SETTINGS_SUCCESS = 'UPDATE_CARD_SETTINGS_SUCCESS';
export const UPDATE_CARD_SETTINGS_FAILURE = 'UPDATE_CARD_SETTINGS_FAILURE';

export interface FetchCardSensitiveDataPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'];
    }
}
export interface DeleteCardPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'];
    },
    locationPathname: LocationPathname;
}

export interface UpdateCardStatusPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'];
        cardStatus: CardDetails['cardStatus'];
    },
    locationPathname: LocationPathname;
}

export interface UnblockCardPinPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'];
        cardToken: CardDetails['cardToken'];
    },
    locationPathname: LocationPathname;
}
export interface ResetContactlessCardLimitPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'];
    },
    locationPathname: LocationPathname;
}
export interface ActivateCardPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'],
        activationToken: CardDetails['cardToken'],
    },
    locationPathname: LocationPathname
}

export interface UpdateCardSettingsPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'],
        isContactlessEnabled: CardDetails['cardSettings']['isContactlessEnabled'],
        isAtmWithdrawalEnabled: CardDetails['cardSettings']['isAtmWithdrawalEnabled'],
        isEcommerceEnabled: CardDetails['cardSettings']['isEcommerceEnabled'],
    },
    locationPathname: LocationPathname
}

export interface UpdateCardLimitsPayload {
    queryParams: {
        cardUuid: CardDetails['cardUuid'],
        hasLimit: CardDetails['cardLimit']['isEnabled'],
        limitMonthly: number, // TODO as part of cardLimit
    },
    locationPathname: LocationPathname
}
