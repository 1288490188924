import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { standardPageSize } from 'config/config';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    ADD_CARD,
    ADD_CARD_SUCCESS,
    ADD_CARD_FAILURE,
    CLEAR_CARDS_LIST,
    FETCH_CARDS_LIST,
    FETCH_CARDS_LIST_FAILURE, FETCH_CARDS_LIST_SUCCESS,
} from './actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from '../standardLists.helpers';


export const cardsReducerName = 'cards';
export const cardsListsPerLocationStoreKeyName = 'cardsListsPerLocation';

export const cardsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'createdAt',
    sortOrder: SortDirections.ASCEND,
    cardStatus: [], // or undefined - TODO double check
    companyUuid: undefined,
    businessUserUuid: undefined,
    accountUuid: undefined,
};

export const cardsInitialState = {
    [cardsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddCard: false,
};

const createCardsBuilderCases = (builder) =>
    builder
        .addCase(FETCH_CARDS_LIST, (state, action) => {
            state[cardsListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[cardsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CARDS_LIST_SUCCESS, (state, action) => {
            state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[cardsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CARDS_LIST_FAILURE, (state, action) => {
            state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[cardsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(ADD_CARD, (state) => {
            state.isLoadingAddCard = true;
        })
        .addCase(ADD_CARD_SUCCESS, (state) => {
            state.isLoadingAddCard = false;
        })
        .addCase(ADD_CARD_FAILURE, (state) => {
            state.isLoadingAddCard = false;
        })
        .addCase(CLEAR_CARDS_LIST, (state, action) => {
            state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[cardsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === cardsReducerName
                        && action.payload?.fieldName === cardsListsPerLocationStoreKeyName
            ) {
                state[cardsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: state?.[cardsListsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return cardsInitialState;
        });

export default createReducer(cardsInitialState, createCardsBuilderCases);
