import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { showToast } from 'store/application/actions';
import { cardsReducerName } from 'store/cards/reducer';
import { updateList } from 'store/list/actions';
import { hideModal } from 'store/modal/actions';


import {
    deleteCardFailure,
    deleteCardSuccess,
    fetchCardDetails,
    fetchCardDetailsFailure,
    fetchCardDetailsSuccess,
    updateCardStatusFailure,
    updateCardStatusSuccess,
    unblockCardPinFailure,
    unblockCardPinSuccess,
    resetContactlessCardLimitSuccess,
    resetContactlessCardLimitFailure,
    activateCardSuccess,
    activateCardFailure,
    updateCardSettingsSuccess,
    updateCardSettingsFailure,
    updateCardLimitsSuccess,
    updateCardLimitsFailure,
} from './actions';
import {
    DELETE_CARD,
    FETCH_CARD_DETAILS,
    UPDATE_CARD_STATUS,
    UNBLOCK_CARD_PIN,
    RESET_CONTACTLESS_CARD_LIMIT,
    ACTIVATE_CARD,
    UPDATE_CARD_SETTINGS,
    UPDATE_CARD_LIMITS,
} from './actions.types';
import { createActivateCardErrorMessage } from './epics.helpers';
import { cardDetailsPerLocationStoreKeyName, cardReducerName } from './reducer';


export const onFetchCardDetails: Epic = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_CARD_DETAILS),
    switchMap(({ payload }) => from(cards.fetchCardDetails(payload.queryParams.cardUuid))
        .pipe(
            switchMap((response) => of(fetchCardDetailsSuccess({
                responsePayload: response.data,
                locationPathname: payload.locationPathname,
            }))),
            catchError(() => of(fetchCardDetailsFailure(payload.locationPathname))),
        )),
);

export const onDeleteCard: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(DELETE_CARD),
    switchMap(({ payload }) => from(cards.deleteCard(payload.queryParams.cardUuid))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    deleteCardSuccess(response),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.deleteCardSuccess') }),
                    fetchCardDetails({
                        queryParams: currentCardDetailsQueryParams,
                        locationPathname: pathname,
                    }),
                    updateList({
                        findByPropValue: payload.queryParams.cardUuid,
                        findByPropName: 'cardUuid',
                        listReducerName: cardsReducerName,
                    }),
                );
            }),
            catchError(() => of(deleteCardFailure())),
        )),
);

export const onUnblockCardPin: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(UNBLOCK_CARD_PIN),
    switchMap(({ payload }) => from(cards.unblockCardPin(payload.queryParams.cardToken))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    fetchCardDetails({ queryParams: currentCardDetailsQueryParams, locationPathname: pathname }),
                    unblockCardPinSuccess(response),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.unblockCardPinSuccess') }),
                );
            }),
            catchError(() => of(unblockCardPinFailure())),
        )),
);

export const onUpdateCardSettings: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(UPDATE_CARD_SETTINGS),
    switchMap(({ payload }) => from(cards.updateCardSettings(payload.queryParams))
        .pipe(
            switchMap(() => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    fetchCardDetails({ queryParams: currentCardDetailsQueryParams, locationPathname: pathname }),
                    updateCardSettingsSuccess(),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.changeCardSettings') }),
                    hideModal(),
                );

            }),
            catchError(() => of(updateCardSettingsFailure())),
        )),
);

export const onUpdateCardStatus: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(UPDATE_CARD_STATUS),
    switchMap(({ payload }) => from(cards.updateCardStatus(payload.queryParams))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    updateCardStatusSuccess({ responsePayload: response.data }),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.changeCardStatus') }),
                    hideModal(),
                    fetchCardDetails({ queryParams: currentCardDetailsQueryParams, locationPathname: pathname }),
                    updateList({
                        findByPropValue: payload.queryParams.cardUuid,
                        findByPropName: 'cardUuid',
                        listReducerName: cardsReducerName,
                    }),
                );
            }),
            catchError(() => of(
                updateCardStatusFailure({ responsePayload: payload.queryParams }),
            )),
        )),
);

export const onResetContactlessCardLimit: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(RESET_CONTACTLESS_CARD_LIMIT),
    switchMap(({ payload }) => from(cards.resetContactlessCardLimit(payload.queryParams.cardUuid))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    fetchCardDetails({ queryParams: currentCardDetailsQueryParams, locationPathname: pathname }),
                    resetContactlessCardLimitSuccess(response),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('cards:actionMessages.resetContactlessCardLimitSuccess'),
                    }),
                );

            }),
            catchError(() => of(resetContactlessCardLimitFailure())),
        )),
);

export const onActivateCard: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(ACTIVATE_CARD),
    switchMap(({ payload }) => from(cards.activateCard(payload.queryParams))
        .pipe(
            switchMap(() => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    fetchCardDetails({ queryParams: currentCardDetailsQueryParams, locationPathname: pathname }),
                    activateCardSuccess(),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.activateCardSuccess') }),
                    hideModal(),
                    updateList({
                        findByPropValue: payload.queryParams.cardUuid,
                        findByPropName: 'cardUuid',
                        listReducerName: cardsReducerName,
                    }),
                );

            }),
            catchError((error) => of(
                showToast({ type: ToastType.error, message: createActivateCardErrorMessage(i18n.t, error) }),
                activateCardFailure(),
            )),
        )),
);


export const onUpdateCardLimits: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(UPDATE_CARD_LIMITS),
    switchMap(({ payload }) => from(cards.updateCardLimits(payload.queryParams))
        .pipe(
            switchMap(() => {
                const pathname = payload.locationPathname;
                const currentCardDetailsQueryParams = state$.value[cardReducerName][cardDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    fetchCardDetails({ queryParams: currentCardDetailsQueryParams, locationPathname: pathname }),
                    updateCardLimitsSuccess(),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.editCardLimitsSuccess') }),
                    hideModal(),
                );

            }),
            catchError(() => of(updateCardLimitsFailure())),
        )),
);

export default [
    onFetchCardDetails,
    onDeleteCard,
    onUnblockCardPin,
    onUpdateCardStatus,
    onResetContactlessCardLimit,
    onActivateCard,
    onUpdateCardSettings,
    onUpdateCardLimits,
];

