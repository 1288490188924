import { NumericId, Uuid } from '@manigo/manigo-domain-typings';

import {
    accountRootRoutePath,
    accountsListRootRoutePath,
    beneficiaryRootRoutePath,
    businessUserRootRoutePath,
    businessUsersListRootRoutePath, cardDetailsRootRoutePath,
    cardsListRootRoutePath,
    editBeneficiaryRootRoutePath,
    genericAddRouteSegmentPath, transactionRootRoutePath, transactionsListRootRoutePath,
    transactionsRouteSegmentPath,
} from 'config/routes';


// directly to ATM one and only tab - transactions
export const createBeneficiariesListPath = () => `${beneficiaryRootRoutePath}`;
export const createBeneficiaryDetailsPath = (beneficiaryUuid: Uuid) => `${beneficiaryRootRoutePath}/${beneficiaryUuid}`;
export const createBeneficiaryDetailsTransactionsListPath = (beneficiaryUuid: Uuid) => `${createBeneficiaryDetailsPath(beneficiaryUuid)}/${transactionsRouteSegmentPath}`;
export const createAddBeneficiaryPath = () => `${beneficiaryRootRoutePath}/${genericAddRouteSegmentPath}`;
export const createEditBeneficiaryPath = (beneficiaryUuid: Uuid) => `${editBeneficiaryRootRoutePath}/${beneficiaryUuid}`;

export const createAccountsListPath = () => `${accountsListRootRoutePath}`;
export const createAccountDetailsPath = (accountId: NumericId) => `${accountRootRoutePath}/${accountId}`;
export const createAccountTransactionsPath = (accountId: NumericId) => `${accountRootRoutePath}/${accountId}/${transactionsRouteSegmentPath}`;


export const createCardsListPath = () => `${cardsListRootRoutePath}`;
export const createCardDetailsPath = (cardUuid: Uuid) => `${cardDetailsRootRoutePath}/${cardUuid}`;

export const createBusinessUsersListPath = () => `${businessUsersListRootRoutePath}`;
export const createBusinessUserDetailsPath = (businessUserId: NumericId) => `${businessUserRootRoutePath}/${businessUserId}`;

export const createTransactionsListPath = () => `${transactionsListRootRoutePath}`;
export const createTransactionDetailsPath = (transactionUuid: Uuid) => `${transactionRootRoutePath}/${transactionUuid}`;


export default {
    createBeneficiariesListPath,
    createAddBeneficiaryPath,
    createBeneficiaryDetailsPath,
    createBeneficiaryDetailsTransactionsListPath,
    createEditBeneficiaryPath,

    createAccountsListPath,
    createAccountDetailsPath,
    createAccountTransactionsPath,
    createCardsListPath,
    businessUsersListPath: createBusinessUsersListPath,
    businessUserDetailsPath: createBusinessUserDetailsPath,

    createTransactionsListPath,
    createTransactionDetailsPath,
};
