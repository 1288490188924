import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';
import { CardType } from '@manigo/manigo-domain-typings';
import { WithTranslation } from 'react-i18next';

import { WithAccessControl } from 'models/domain/accessControl';


export enum AddCardFormSteps {
    BASIC_DATA = 'ADD_CARD_BASIC_DATA',
    ADDRESS = 'ADD_CARD_ADDRESS',
    REVIEW = 'ADD_CARD_REVIEW',
    SUCCESS = 'ADD_CARD_SUCCESS',
}

export interface AddCardPageStateProps {
    enhancedCurrentLocation: EnhancedLocation,
    isLoadingAddCard: boolean,
    context?: { cardType?: CardType },
    newEntityLocation?: string

}

export interface AddCardPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }:LocationChangeRequest) => void,
}

export type AddCardPageProps = AddCardPageStateProps & AddCardPageDispatchProps & WithTranslation & WithAccessControl;
