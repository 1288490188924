import { ReactChildrenProp } from './children';


export enum MultiStepFormResetScrollElements {
  mainStepsClassName = 'reset-scroll-step-without-sub-steps',
  subStepsClassName = 'reset-scroll-step-with-sub-steps',
}

export enum MultiStepFormStepChangeDirections {
    FORWARD = 'FORWARD',
    BACK = 'BACK',
}

export enum MultiStepFormCommonStepsDefinitions {
    SUCCESS = 'SUCCESS',
    ADDRESSES = 'ADDRESSES',
}

export enum MultiStepFormStepSectionTypes {
    EVEN_COLUMNS = 'EVEN_COLUMNS', // default, default colCount = 1
    AUTO_ROWS = 'AUTO_ROWS', // auto height rows in single col
    GRID = 'GRID', // (auto height) rows & cols (default=2)
}

export enum MultiStepFormStepSectionFieldsLayout {
    VERTICAL = 'VERTICAL', // default, field one under another, full width
    AUTO_HORIZONTAL = 'AUTO_HORIZONTAL', // fields render in row with warping to next rows, element width is fit-content
    EVEN_HORIZONTAL = 'EVEN_HORIZONTAL', // fields render in row with warping to next rows, element width is equal, default 50%
}

export interface MultiStepsFormStepSectionConfig {
    title: string,
    children: ReactChildrenProp,
}

export interface MultiStepsFormStepConfig {
    stepName: string,
    title: string,
    icon?: ReactChildrenProp,
    children: ReactChildrenProp,
}


export default {
    MultiStepFormResetScrollElements,
    MultiStepFormStepChangeDirections,
    MultiStepFormCommonStepsDefinitions,
};

