import { ApiError, ApiResponse, convertCamelCaseToSnakeCase, cleanNilValues, SortDirectionsAPI } from '@manigo/manigo-commons';
import { CardDetails, SubmitScaChallengeActionPayload, Uuid } from '@manigo/manigo-domain-typings';

import { SortDirections } from 'models/app/applicationState';

import { addScaHeaders, withTotalCount } from 'services/http/http.helpers';


import { UpdateCardSettingsPayload, UpdateCardStatusPayload } from '../../store/card/actions.types';

import {
    getAllCardsApi,
    processOrderNewCardApiRequest,
    getNotLinkedCardsApi,
    unblockCardPinByCardToken,
    resetContactlessCardLimitApi,
    deleteCardUuid,
    updateCardUuidSettings,
    updateCardUuidLimits,
    unlinkCardUuid,
    linkCardUuid,
    getCardUuidImage,
    getCardUuidStatusHistory,
    activateCardUuid,
    getCardUuidSensitiveData, fetchCardProductsApi, getCardUuidDetails, updateCardUuidStatus,
} from './index';


class CardsService {
    public fetchAllCards(rawQuery) {
        const { sortOrder, search, cardStatus, sortBy, ...rest } = rawQuery;
        const query = {
            ...rest,
            cardStatus: cardStatus?.length > 0 ? cardStatus : undefined,
            ...(search && search?.length > 0 ? { search } : {}),
            sortBy: sortBy ? convertCamelCaseToSnakeCase(sortBy) : undefined,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };

        return getAllCardsApi({ params: { ...cleanNilValues(query) } });
    }

    public fetchCardDetails(cardUuid: Uuid) {
        return getCardUuidDetails(cardUuid);
    }

    public updateCardSettings(rawRequestPayload: UpdateCardSettingsPayload['queryParams']) {
        const { cardUuid, ...requestPayload } = rawRequestPayload;
        return updateCardUuidSettings(cardUuid, requestPayload);
    }

    public updateCardStatus(rawRequestPayload: UpdateCardStatusPayload['queryParams']) {
        const { cardUuid, ...requestPayload } = rawRequestPayload;
        return updateCardUuidStatus(cardUuid, requestPayload);
    }

    public updateCardLimits({ cardUuid, ...requestPayload }) {
        return updateCardUuidLimits(cardUuid, requestPayload);
    }

    public unlinkCard(cardUuid) {
        return unlinkCardUuid(cardUuid);
    }

    public linkCard(cardUuid, requestPayload) {
        return linkCardUuid(cardUuid, requestPayload);
    }

    public fetchCardProducts(requestPayload) {
        return fetchCardProductsApi(requestPayload);
    }


    public getCardImage(cardId) {
        return getCardUuidImage(cardId);
    }

    public unblockCardPin(cardToken) {
        const requestPayload = { token: cardToken };
        return unblockCardPinByCardToken(requestPayload);
    }

    public processOrderNewCard(requestPayload) {
        return processOrderNewCardApiRequest(requestPayload);
    }

    public getNotLinkedCards(rawQuery) {
        const { companyId, clientId, memberId, search, ...rest } = rawQuery;

        const query = {
            ...rest,
            ...withTotalCount,
            ...(companyId > 0 && { companyId }),
            ...(clientId > 0 && { clientId }),
            ...(memberId > 0 && { memberId }),
            ...(search && search.length > 0 && { search }),
        };

        return getNotLinkedCardsApi({ params: query });
    }

    public deleteCard(cardUuid: Uuid) {
        return deleteCardUuid(cardUuid);
    }

    public getCardStatusHistory(rawQueryParams) {
        const { cardId, sortOrder, ...rest } = rawQueryParams;
        const queryParams = {
            ...rest,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };
        return getCardUuidStatusHistory(cardId, { params: { ...cleanNilValues(queryParams) } });
    }

    public resetContactlessCardLimit(cardUuid: CardDetails['cardUuid']) {
        return resetContactlessCardLimitApi(cardUuid);
    }

    public activateCard(rawQueryParams: { cardUuid: CardDetails['cardUuid'], activationToken: CardDetails['cardToken'] }) {
        const { cardUuid, ...rest } = rawQueryParams;

        return activateCardUuid(cardUuid, rest);
    }

    public getCardSensitiveData(requestPayload: SubmitScaChallengeActionPayload): Promise<ApiResponse | ApiError> {
        const { cardUuid } = requestPayload.sourceRequestData;
        const config = addScaHeaders(requestPayload, { noErrorHandling: true });
        return getCardUuidSensitiveData(cardUuid, config);
    }
}

export default CardsService;

