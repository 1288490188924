// https://uxwing.com/visa-icon/
// https://uxwing.com/master-card-icon/
// ^^^ License: All icons are free to use any personal and commercial projects without any attribution or credit.


import /* webpackPreload: true */ /* webpackChunkName: "master_card_icon" */ masterCardIcon
    from 'assets/images/cardScheme/master-card-icon.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "visa_icon" */ visaIcon
    from 'assets/images/cardScheme/visa-icon.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "maintenance_cogs" */ maintenanceCogs
    from 'assets/images/maintenance-cogs.svg?url';


const demoImages = { maintenanceCogs, visaIcon, masterCardIcon };


export default demoImages;
