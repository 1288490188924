import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { Epic } from 'models/meta/epic';

import { cardsListRootRoutePath } from 'config/routes';
import { createCardDetailsPath } from 'config/routes.helpers';

import { requestSetMultiStepsFormStep, setNewEntityLocation } from 'store/multi-steps-form/actions';

import { AddCardFormSteps } from 'components/pages/authorised/AddCardPage/AddCardPage.types';

import { addCardFailure, addCardSuccess, fetchCardsList, fetchCardsListFailure, fetchCardsListSuccess } from './actions';
import { ADD_CARD, FETCH_CARDS_LIST } from './actions.types';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from './reducer';
import { addCompanyIdToQueryParams } from '../common.helpers';


export const onAddCard: Epic = (action$, state$, { cards }) => action$.pipe(
    ofType(ADD_CARD),
    switchMap(({ payload }) => from(cards.processOrderNewCard({
        ...payload.requestPayload,
        companyUuid: 'ba5ac514-179a-45f5-9ebc-89211a0df3e4', // TODO global companyUuid
        // companyUuid: state$.value[currentUserReducerName].userData?.identifiers?.companyUuid
    }))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentCardListQueryParams = state$.value[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[cardsListRootRoutePath]?.queryParams;

                return of(
                    addCardSuccess({ locationPathname: pathname, requestPayload: payload }),
                    setNewEntityLocation(createCardDetailsPath(response?.data?.cardUuid)),
                    fetchCardsList({ queryParams: currentCardListQueryParams ?? {}, locationPathname: pathname }),
                    requestSetMultiStepsFormStep(
                        AddCardFormSteps.REVIEW,
                        MultiStepFormStepChangeDirections.FORWARD,
                    ),
                );
            }),
            catchError(({ locationPathname }) => of(addCardFailure({ locationPathname }))),
        )),
);


export const onFetchCardsList: Epic = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_CARDS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(
        cards.fetchAllCards(addCompanyIdToQueryParams({ state$, queryParams, useCompanyUuid: true })),
    ).pipe(
        switchMap((response) => of(fetchCardsListSuccess({
            items: response?.data?.rows,
            totalCount: response?.data?.count,
            locationPathname,
        }))),
        catchError(() => of(fetchCardsListFailure({ locationPathname }))),
    )),
);

export default [
    onFetchCardsList,
    onAddCard,
];

