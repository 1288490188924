import { CardDetails, Uuid } from '@manigo/manigo-domain-typings';

import { HttpService } from 'services/http';

import { HttpRequestConfig } from '../http/httpService.types';


export const getAllCardsApi = (queryParams) => HttpService.get('/v4/cards', queryParams);

export const getCardUuidDetails = (cardUuid: Uuid) => HttpService.get(`/v4/cards/${cardUuid}`);

export const updateCardUuidSettings = (cardUuid: Uuid, requestPayload) => HttpService.put(`/v4/cards/${cardUuid}/settings`, requestPayload);
export const updateCardUuidStatus = (cardUuid: Uuid, requestPayload) => HttpService.put(`/v4/cards/${cardUuid}/status`, requestPayload);

export const getCardUuidSensitiveData = (cardUuid: Uuid, config: HttpRequestConfig) =>
    HttpService.get(`/v4/cards/${cardUuid}/sensitive`, config);
export const fetchCardProductsApi = (requestPayload) => HttpService.get('/v4/card-products', requestPayload);

export const activateCardUuid = (cardUuid: Uuid, requestPayload: { activationToken: CardDetails['cardToken'] }) => HttpService.post(
    `/v4/cards/${cardUuid}/activate`,
    requestPayload,
    { noErrorHandling: true },
);
export const processOrderNewCardApiRequest = (requestPayload) => HttpService.post('/v4/cards', requestPayload);

export const unblockCardPinByCardToken = (requestPayload) => HttpService.post('/v2/cards/unblock-pin', requestPayload);


export const updateCardUuidLimits = (cardUuid, requestPayload) => HttpService.put(`/v2/cards/${cardUuid}/limits`, requestPayload);


export const linkCardUuid = (cardUuid: Uuid, requestPayload) => HttpService.post(`/v2/cards/${cardUuid}/link`, requestPayload);

export const unlinkCardUuid = (cardUuid: Uuid) => HttpService.post(`/v2/cards/${cardUuid}/unlink`, {});


export const getCardUuidImage = (cardUuid: Uuid) => HttpService.get(`/v2/cards/${cardUuid}/image`);

export const getNotLinkedCardsApi = (queryParams) => HttpService.get('/v3/cards/not-linked', queryParams);

export const deleteCardUuid = (cardUuid: Uuid) => HttpService.delete(`/v2/cards/${cardUuid}`);

export const getCardUuidStatusHistory = (cardUuid: Uuid, queryParams) => HttpService.get(`/v2/cards/${cardUuid}/status-history`, queryParams);

export const resetContactlessCardLimitApi = (cardUuid: Uuid) => HttpService.post(`/v2/cards/${cardUuid}/reset-contactless-limit`);

