import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { withTotalCount } from 'services/http/http.helpers';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    CLEAR_TRANSACTIONS_LIST,
    FETCH_TRANSACTIONS_LIST, FETCH_TRANSACTIONS_LIST_FAILURE,
    FETCH_TRANSACTIONS_LIST_SUCCESS,
} from './actions.types';


export const transactionsReducerName = 'transactions';
export const transactionsListsPerLocationStoreKeyName = 'transactionsListsPerLocation';

export const defaultTransactionsListQueryParams = {
    limit: 20,
    offset: 0,
    type: undefined,
    currency: undefined,
    companyId: undefined,
    businessUserId: undefined,
    cardUuid: undefined,
    sortBy: 'createdAt',
    sortOrder: SortDirections.DESCEND,
    ...withTotalCount,
};

export const transactionsInitialListState = {
    [transactionsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }

    downloadPdfStatement: {},
};

const createTransactionsBuilderCases = (builder) =>
    builder
        .addCase(FETCH_TRANSACTIONS_LIST, (state, action) => {
            state[transactionsListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[transactionsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_TRANSACTIONS_LIST_SUCCESS, (state, action) => {
            state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[transactionsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_TRANSACTIONS_LIST_FAILURE, (state, action) => {
            state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[transactionsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_TRANSACTIONS_LIST, (state, action) => {
            state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[transactionsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === transactionsReducerName
                        && action.payload?.fieldName === transactionsListsPerLocationStoreKeyName
            ) {
                state[transactionsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams(
                    {
                        listsState: state?.[transactionsListsPerLocationStoreKeyName],
                        action,
                    },
                );
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => transactionsInitialListState);

export default createReducer(transactionsInitialListState, createTransactionsBuilderCases);
